/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMember = /* GraphQL */ `
  query GetMember($id: ID!) {
    getMember(id: $id) {
      id
      uid
      name
      dojo
      state
      country
      grade
      instructor
      chiefInstructor
      dateOfBirth
      gradingHistory
      createdAt
      updatedAt
    }
  }
`;
export const listMembers = /* GraphQL */ `
  query ListMembers(
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        uid
        name
        dojo
        state
        country
        grade
        instructor
        chiefInstructor
        dateOfBirth
        gradingHistory
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
