import React, { useState, useEffect } from "react";
import "@aws-amplify/ui-react/styles.css";
import { API } from "aws-amplify";
import {
  Button,
  CheckboxField,
  Flex,
  Heading,
  Text,
  TextField,
  TextAreaField,
  View,
  Authenticator,
  withAuthenticator,
} from "@aws-amplify/ui-react";
import { listMembers } from "../graphql/queries";
import {
  createMember as createMemberMutation,
  deleteMember as deleteMemberMutation,
} from "../graphql/mutations"
import styles from './members.module.css'
import cx from 'classname'

const Member = (props) => {
  const [members, setMembers] = useState([]);

  useEffect(() => {
    fetchMembers();
  }, []);

  async function fetchMembers() {
    const apiData = await API.graphql({ query: listMembers });
    const membersFromAPI = apiData.data.listMembers.items;
    setMembers(membersFromAPI);
  }

  async function createMember(event) {
    event.preventDefault();
    const form = new FormData(event.target);
    const data = {
      name: form.get("name"),
      dojo: form.get("dojo"),
      grade: form.get("grade"),
      instructor: form.get("instructor"),
      chiefInstructor: form.get("chiefInstructor"),
    };
    await API.graphql({
      query: createMemberMutation,
      variables: { input: data },
    });
    fetchMembers();
    event.target.reset();
  }

  async function deleteMember({ id }) {
    const newMembers = members.filter((member) => member.id !== id);
    setMembers(newMembers);
    await API.graphql({
      query: deleteMemberMutation,
      variables: { input: { id } },
    });
  }

  return (
    <Authenticator hideSignUp>
      {({ signOut, user }) => (
        <View className={cx("App", styles.root)}>
          <Heading level={2}>My Members App</Heading>
          <View as="form" margin="3rem 0" onSubmit={createMember}>
            <Flex direction="row" justifyContent="center">
              <TextField
                name="name"
                placeholder="Member Name"
                label="Member Name"
                labelHidden
                variation="quiet"
                required
              />
              <TextField
                name="dojo"
                placeholder="Dojo Name"
                label="Dojo Name"
                labelHidden
                variation="quiet"
                required
              />
              <TextField
                name="grade"
                placeholder="Belt Grade"
                label="Current Grade"
                labelHidden
                variation="quiet"
                required
              />
              <CheckboxField
                label="instructor"
                name="instructor"
                value="yes"
              />
              <CheckboxField
                label="chiefInstructor"
                name="chiefInstructor"
                value="yes"
              />

              <Button type="submit" variation="primary">
                Create Member
              </Button>
            </Flex>
          </View>
          <Heading level={2}>Current Members</Heading>
          <View margin="3rem 0">
            {members.map((member) => (
              <Flex
                key={member.id || member.name}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Text as="strong" fontWeight={700}>
                  {member.name}
                </Text>
                <Text as="span">{member.dojo}</Text>
                <Text as="span">{member.grade}</Text>
                <Text as="span">{member.instructor}</Text>
                <Text as="span">{member.chiefInstructor}</Text>
                {/* <Text as="span">{member.grade}</Text> */}
                <Button variation="link" onClick={() => deleteMember(member)}>
                  Delete member
                </Button>
              </Flex>
            ))}
          </View>
          <Button onClick={signOut}>Sign Out</Button>
        </View>
      )}
    </Authenticator>
  );
};

export default Member;