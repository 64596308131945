
import React, { useState, useEffect } from 'react'
import slider_2 from "../img/slider_2.jpg"
import {Leftpanel} from '../components/components'
import styles from './home.module.css'
import cx from 'classname'

export default function Home(){
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  useEffect(()=> {
    document.title = "Home : UskIndia"
  }, []);
  return (
    <main className={styles.main}>
      <Leftpanel />
      <section className={cx("col", styles.home)}>
        <div className={cx("col-auto col-md-8", styles.carousel)}>
          <div className='Carousel' activeIndex={index} onSelect={handleSelect} nextLabel="" prevLabel="" fade  >
            <div className="Carousel.Item" interval={2000}>
              <img className="d-block w-100" src={slider_2} alt="Second slide" />
            </div>
          </div>
        </div>
        <div className={cx("col", styles.text)}>
          <p>
            <strong>USK India</strong> is acronym for 
            <strong className="yel" > Universal Shotokan Karate-do India
            </strong>
            . This is the leading as well as emerging karate Organization in India , and Now an International Style. It represents the enlightening knowledge achieved through sheer hard work, dedication, perseverance and concentration of Masters of shotokan Karate. It is the dedication, spirit and Hard Work of Masters of Shotokan karate which made this style more colorful and favourite to majority poeple. This is the most natural style of Karate.
          </p>
          <p>
            <strong>Hanshi Janak Raj Jamwal</strong> who is the head of this style in India and took shotokan karate to new heights of Success.
          </p>
          <p>
            This Organization has evolved at lot with in these 4-5 years. There are Dojo's of USK India in almost every State in India. With the hard work and affection of masters towards Karate. This Style has become most popular in India in very short span of time.
          </p>
        </div>
      </section>
    </main>
  )
}