
import React, { useEffect } from 'react'
import admins_1 from "../img/admins_1.jpg"
import admins_3 from "../img/admins_3.jpg"
import male from "../img/Male.jpg"
import female from "../img/Female.jpg"
import {Leftpanel} from '../components/components'
import cx from 'classname'
import styles from './admins.module.css'

export function Admins(props) {
  useEffect(()=> {
    document.title = "Administration : UskIndia"
  }, []);
  return (
    <main className={styles.main}>
      <Leftpanel />
      <section className={cx("col", styles.admins)}>
        <div className={cx("row", styles.adminRow)}>
          <div className={cx("col", styles.adminHeader)}>
            President & Chief Instructor 
            <b>(Permanent Committee Member)</b>
            <hr />
          </div>
          <div className={cx(styles.adminDetail)}>
            <div className={cx("col-4 mx-auto", styles.avtar)}>
              <img 
                className="img-fluid rounded mx-auto d-block" 
                src={admins_1} 
                alt="admin_1"
              />
              <div className="text-center">Hanshi Janak Raj Jamwal</div>
            </div>
            <div className={cx("col-7 mx-auto qualification", styles.qualification)}>
              8th Dan Black Belt International<br />
              7th Dan Black Belt, WKF <br/>
              6th Dan Black Belt, Japan <br/>
              5th Dan Black Belt, IAKO <b/>
              5th Dan Black Belt, Shito-Ryu <br/>
              Vice Chairman Technical Commission, KIO <br/>
              Executive Body Member, KIO <br/>
              KIO Kumite Referee &amp; Kata Judge <br />
              National Certified Coach <br/>
              Instructor, Referee, Examiner (A- Grade) International
            </div>
          </div>
        </div>

        <div className={cx("row", styles.adminRow)}>
          <div className={cx("col", styles.adminHeader)}>
            Vice President <b >(Permanent Committee Member)</b>
            <hr />
          </div>
          <div className={cx(styles.adminDetail)}>
            <div className={cx("col-4 mx-auto", styles.avtar)}>
              <img 
                className="img-fluid rounded mx-auto d-block" 
                src={male}
                alt="placeholder"
              />
              <div className="text-center">Renshi Annushakti Singh </div>
            </div>
            <div className={cx("col-7 mx-auto qualification", styles.qualification)}>
              7th  Dan Black Belt, USKI <br/>
              Judge Kata & Kumite, KIO <br/>
            </div>
          </div>
        </div>

        <div className={cx("row", styles.adminRow)}>
          <div className={cx("col", styles.adminHeader)}>
            Director <b>(Permanent Committee Member)</b>
            <hr />
          </div>
          <div className={cx(styles.adminDetail)}>
            <div className={cx("col-4 mx-auto", styles.avtar)}>
              <img 
                className="img-fluid rounded mx-auto d-block" 
                src={male}
                alt="placeholder"
              />
              <div className="text-center">Sensei Pawan Thakur</div>
            </div>
            <div className={cx("col-7 mx-auto qualification", styles.qualification)}>
              6th Dan Black Belt, USKI <br/>
              2nd Dan Black Belt, Japan <br/>
              Kumite Referee-A and Kata Judge-A, KIO <br/> 
              National Certified Coach <br/>
            </div>
          </div>
        </div>

        <div className={cx("row", styles.adminRow)}>
          <div className={cx("col", styles.adminHeader)}>
            General Secretary <b>(Permanent Committee Member)</b>
            <hr />
          </div>
          <div className={cx(styles.adminDetail)}>
            <div className={cx("col-4 mx-auto", styles.avtar)}>
              <img 
                className="img-fluid rounded mx-auto d-block" 
                src={male}
                alt="placeholder"
              />
              <div className="text-center">Daaman Jamwal</div>
            </div>
            <div className={cx("col-7 mx-auto qualification", styles.qualification)}>
              6th Dan Black Belt, USK India<br/>
              5th Dan Black Belt, Shotokan Australia<br/>
              4th Dan Black Belt, WKF<br/>
              3rd Dan Black Belt, Japan <br/>
              A Grade Referee & Judge, KIO <br/>
              WKF Acredited Coach <br/>
              National Certified Coach <br/>
              International Instructor, Referee, Examiner (B- Grade) 
              <br/>
            </div>
          </div>
        </div>

        <div className={cx("row", styles.adminRow)}>
          <div className={cx("col", styles.adminHeader)}>
            Joint Secretary <b>(Permanent Committee Member)</b>
            <hr />
          </div>
          <div className={cx(styles.adminDetail)}>
            <div className={cx("col-4 mx-auto", styles.avtar)}>
              <img 
                className="img-fluid rounded mx-auto d-block" 
                src={female} 
                alt="placeholder"
              />
              <div className="text-center">Kanchan Thakur</div>
            </div>
            <div className={cx("col-7 mx-auto qualification", styles.qualification)}>
              5th Dan Black Belt, USKI <br/>
              3rd Dan Black Belt, Japan <br/>
              B Grade Referee & Judge, KAI <br/> 
              National Certified Coach <br/>
              International Instructor, Referee, Examiner (B- Grade) <br/>
            </div>
          </div>
        </div>

        
        <div className={cx("row", styles.adminRow)}>
          <div className={cx("col", styles.adminHeader)}>
            Treasurer <b>(Permanent Committee Member)</b>
            <hr />
          </div>
          <div className={cx(styles.adminDetail)}>
            <div className={cx("col-4 mx-auto", styles.avtar)}>
              <img 
                className="img-fluid rounded mx-auto d-block" 
                src={admins_3}
                alt="admin_3"
              />
              <div className="text-center">Sensei Ruchika Jamwal</div>
            </div>
            <div className={cx("col-7 mx-auto qualification", styles.qualification)}>
              6th Dan Black Belt, USKI India <br/>
              5th Dan Black Belt, Japan <br/>
              Referee & Judge, KIO <br/>
              Certified National Coach <br />
              International Instructor, Referee, Examiner (B- Grade)
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}


export function Contact(props) {
  useEffect(()=> {
    document.title = "Contact Us : UskIndia"
  }, []);
  return (
    <main className={styles.main}>
      <Leftpanel />
      <section className={cx("col", styles.admins, styles.contactUs)}>
        <div className={cx("row", styles.adminRow)}>
          <div className={cx("col", styles.adminHeader)}>
            Contact Universal Shotokan Karate-Do India
            <hr />
          </div>
          <div className={cx(styles.adminDetail)}>
            <div className={cx("col-4 mx-auto", styles.avtar)}>
              <img 
                className="img-fluid rounded mx-auto d-block"  
                src={admins_1} 
                alt="admin_1"
              />
              <div className="text-center">Hanshi Janak Raj Jamwal</div>
              <div className="text-center">Black Belt 8th Dan</div>
            </div>
            <div className={cx("col-7 mx-auto qualification", styles.qualification)}>
              <strong>HEADQUARTER</strong>
              <hr />
              V.P.O. BAIJNATH PANDOL ROAD<br/>
              DISTT. KANGRA HIMACHAL PRADESH, POSTAL CODE: 176125<br/>
              CONTACT NO: - +91-98164-33684<br/>
              <strong>Email</strong> : 
              <a href="mailto:uskuindia@yahoo.com">
                <b>uskuindia@yahoo.com</b>
              </a>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}