
import React, { useEffect } from 'react'
import {Leftpanel} from '../components/components'
import cx from 'classname'
import styles from './about.module.css'
export default function About(props){
  useEffect(()=> {
    document.title = "About Us : UskIndia"
  }, []);
  return (
    <main className={styles.main}>
      <Leftpanel />
      <section className={cx("col mx-auto mb-3 me-3", styles.about)}>
        <div className={cx("col", styles.text)}>
          <h3 className="text-center">About Universal Shotokan Karate India</h3>
            <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong className="name_text">Hanshi Janak Raj Jamwal</strong> is the founder and chief instructor of the <strong className="degree_text">"Universal Shotokan karate-do India"</strong>, which he established in 2010. He started his karate career with Goju-ryu style in 1982, with which he was affiliated until 1987. <strong className="name_text">Hanshi Janak Raj jamwal</strong> began his Shotokan karate training in 1988 and continued his training, with the <strong className="degree_text">"Shotokan Karate International federation India (SKI)"</strong>, with which he was affiliated until 1999. <strong className="name_text">Hanshi Janak Raj jamwal</strong> started his teaching career in 1988, with many years of experience and professional instruction in karate training and dojo management. He organized and taught Shotokan karate programs and self-defense at several colleges and universities. After (SKI) he joins JKA India, with which he was affiliated until 2007.
          </p>
          <p>
            <strong className="name_text">Hanshi Janak Raj jamwal</strong> is currently <strong>Black Belt 8th Dan</strong>, <strong>Black Belt 6th Dan WKF &amp; KAI</strong>, Accredited Coach KAI, KAI Referee, Judge &amp; Examiner &amp; A Kyu International Referee, Judge &amp; Examiner..
          </p>
          <p>
            During his long career, <strong className="name_text">Hanshi Janak Raj jamwal</strong> has developed new methods for teaching traditional and sports karate, and includes comparative analysis between them and other methods in his seminars. He has developed new concepts for generating inner power and strength in karate techniques through proper breathing and has pioneered teaching methods for developing speed and agility at any age. <strong className="name_text">Hanshi Janak Raj jamwal</strong> teaches his students a wide range of traditional and sport karate. He emphasizes the philosophy and history of Karate-do  in all his seminars and classNamees. <strong className="name_text">Hanshi Janak Raj jamwal's</strong> students, who train with him for competitions at the national, bring home medals.
          </p>
          <p>
            <strong className="name_text">Mr. Janak Raj jamwal</strong>, through his dedication, determination, and devotion to Karate-Do over Thirty  years, has achieved <strong className="degree_text">"7th degree black belt in Shotokan"</strong>, <strong className="degree_text">"6th degree black belt in WKF"</strong>, <strong className="degree_text">"6th degree black belt in KAI"</strong>, <strong className="degree_text">"5th degree black belts from Shito-Ryu"</strong>, and <strong className="degree_text">"5th degree black belt in IAKO"</strong>. These certifications are recognized nationally and internationally.
          </p>
          <p>
            In addition to his many other accomplishments, <strong>Hanshi Janak Raj jamwal</strong> has extensive experience developing successful karate dojos in <strong>India</strong>.
          </p>
          <br/>
          <span className="btmpart">
            <h3>Hanshi Janak Raj Jamwal</h3>
            <h5>Black Belt 8th Dan</h5>
        </span>
        </div>
      </section>
    </main>
  );
}
