
// import React, { useEffect, useState } from 'react'
// import {Leftpanel} from '../components/components'
// import {graphql_url, getGraphqlRequestOptions, grade_mapping} from '../../helper/helper'
// import Button from 'react-bootstrap/Button'

// // function simulateNetworkRequest() {
// //   return new Promise((resolve) => setTimeout(resolve, 1000));
// // }

// export default function Blackbelts(props){
//   const [isLoading, setLoading] = useState(true);
//   const [members, setMembers] = useState([])
//   const [offset, setOffset] = useState(0)
//   const [hasPrev, setHasPrev] = useState(false)
//   const [hasNext, setHasNext] = useState(false)
//   const pageSize = 10

//   useEffect(() => {
//     const loadData = () => {
//       setLoading(true)
//       const requestOptions = getGraphqlRequestOptions(pageSize, offset, props.type)
//       fetch(graphql_url, requestOptions)
//         .then(response => {
//           setLoading(false)
//           return response.json()
//         })
//         .then(data => {
//           setHasNext(data.data.allMembers.pageInfo.hasNextPage)
//           setMembers(data.data.allMembers.edges)
//           setHasPrev(offset !== 0)
//         })
//     }
//     loadData()
//   }, [props.type, offset]);

//   const handleNextClick = () => {
//     setOffset(offset + pageSize)
//   };
//   const handlePrevClick = () => {
//     setOffset(offset - pageSize)
//   };

//   useEffect(()=> {
//     if (props.type === "dojos"){
//       document.title = "Dojos : UskIndia"
//     }else if(props.type === "instructors"){
//       document.title = "Instructors : UskIndia"
//     }else{
//       document.title = "Blackbelts : UskIndia"
//     }
//     setOffset(0)
//   }, [props.type]);

//   return (
//     <>
//       <Leftpanel />
//       <div className="col mx-auto mb-3 me-3">
//         <div className="row mb-2">
//           <div className="col text-justify mx-3 p-3 members_col">
//             <table className="table border w-100">
//               <thead>
//                 <tr className="border border-left-0 border-right-0">
//                   <th>Sr</th>
//                   {
//                     props.type === 'instructors' ? 
//                     ( <>
//                         <th>Name</th>
//                         <th>Grade</th>
//                         <th>Home Dojo</th>
//                       </>
//                     ) : 
//                     [
//                       props.type === 'dojos' ?  
//                       <th>Dojo Name</th> : 
//                       <th>Name</th>
//                     ]
//                   }
//                   <th colSpan="2">Address</th>
//                   {
//                     props.type === 'instructors' ? null :
//                     [props.type === 'dojos' ?  <th>Chief Instructor</th> : <th>Current Grade</th>]
//                   }
                  
//                 </tr>
//               </thead>
//               <tbody>
//                 { 
//                 members.map((instance, index) => 
//                   <tr key={index}>
//                     <th>{index + 1 + offset}</th> 
//                     {
//                       props.type === 'instructors' ?
//                       (
//                         <>
//                           <td>{instance.node.fullName}</td>
//                           <td>{grade_mapping[instance.node.currentGrade]}</td>
//                           <td>{ instance.node.homeDojo }</td>
//                         </>
//                       ):
//                       [
//                         props.type === 'dojos' ?  
//                         <td>{ instance.node.homeDojo }</td> : 
//                         <td>{instance.node.fullName}</td>
//                       ]
//                     }
                    
//                     <td colSpan="2">{ instance.node.distt } ({instance.node.state})</td>

//                     {
//                       props.type === 'instructors' ? null :
//                       [
//                         props.type === 'dojos' ?  
//                         <td>{instance.node.fullName}</td> : 
//                         <td>{grade_mapping[instance.node.currentGrade]}</td>
//                       ]
//                     }
                    
//                   </tr>
//                 )
//                 }
//               </tbody>
              
//             </table>
//           </div>
//         </div>
//         <div className="row mb-5">
//             <Button 
//               variant="outline-primary"
//               className="col-1 me-auto"
//               disabled={!(hasPrev || isLoading)}
//               size="sm" block
//               onClick={!isLoading ? handlePrevClick : null}
//             >
//               {isLoading ?  'Loading...' : 'Previous'}
//             </Button>
//             <Button 
//               className="col-1 ms-auto"
//               disabled={!(hasNext || isLoading)}
//               onClick={!isLoading ? handleNextClick: null}
//               variant="outline-primary" size="sm" block
//             >
//               {isLoading ?  'Loading...' : 'Next'}
//             </Button>
//         </div>
//       </div>
//     </>
//   );
// }

export default function Blackbelts() {
  return (
    <h1> Blackbelts Page</h1>
  )
}