import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./views/home";
import {Admins, Contact} from "./views/admins";
import Blackbelts from "./views/blackbelts";
import About from "./views/about";
import {Header, Footer} from './components/components'
import Member from './views/member';
import Layout from './views/layout';
export default function App() {
  return (
    <BrowserRouter>
      {/* <Header /> */}
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="admins" element={<Admins />} />
          <Route path="blackbelts" element={<Blackbelts />} />
          <Route path="contact" element={<Contact />} />
          <Route path="members" element={<Member />} />
          {/* <Route path="*" element={<NoPage />} /> */}
        </Route>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}