
import {useState, useEffect} from 'react'
import cx from 'classname'
import logo from "../img/logo.png"
import main from "../img/main.png"
import styles from './components.module.css'
import { Outlet, Link } from "react-router-dom";

export function Header(){
  return (
    <header className={styles.header}>
      <Link to ="/" className={styles.logo}><img src={logo} alt="logo"/></Link>
      <div className={styles.headerText}>
        <span className="ms-3">
          <b style={{fontFamily: "arial-black", fontSize:"1.5rem"}}>UNIVERSAL SHOTOKAN KARATE DO INDIA</b>
        </span>
        <img src={main} className="img-fluid" alt="header-jp-img"/>
      </div>
      <nav className={styles.navs}>
        <Link to ="/">home</Link>
        <Link to ="/about">About</Link>
        <Link to ="/admins">Administration</Link>
        {/* <Link to ="/gallary">Gallary</Link> */}
        <a href="https://www.instagram.com/universaldojo/" target="_blank">Gallary</a>
        <Link to ="/contact">Contact</Link>
      </nav>
    </header>
  )
}

export  function Footer(){
  return (
    <footer className={styles.footer}>
      <span>© Copyrights USK India</span>
    </footer>
  );
}

export function Leftpanel(){  
  return (
    <aside className={cx("col-lg-3 col-xl-2 mx-auto d-none d-lg-block",  styles.aside)}>
      <div className={ cx("row mx-auto mt-4", styles.slidingElement) }>
        {/* <Link to ="/dojos" className={ cx("col-11 mx-auto")}>Our Dojos</Link>
        <Link to ="/blackbelts" className={ cx("col-11 mx-auto")}>Our Blackbelts</Link>
        <Link to ="/instructors" className={ cx("col-11 mx-auto")}>Our Instructors</Link> */}
        <Link to ="/members" className={ cx("col-11 mx-auto")}>Our Members</Link>
      </div>
    </aside>
  )
}
