/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMember = /* GraphQL */ `
  mutation CreateMember(
    $input: CreateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    createMember(input: $input, condition: $condition) {
      id
      uid
      name
      dojo
      state
      country
      grade
      instructor
      chiefInstructor
      dateOfBirth
      gradingHistory
      createdAt
      updatedAt
    }
  }
`;
export const updateMember = /* GraphQL */ `
  mutation UpdateMember(
    $input: UpdateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    updateMember(input: $input, condition: $condition) {
      id
      uid
      name
      dojo
      state
      country
      grade
      instructor
      chiefInstructor
      dateOfBirth
      gradingHistory
      createdAt
      updatedAt
    }
  }
`;
export const deleteMember = /* GraphQL */ `
  mutation DeleteMember(
    $input: DeleteMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    deleteMember(input: $input, condition: $condition) {
      id
      uid
      name
      dojo
      state
      country
      grade
      instructor
      chiefInstructor
      dateOfBirth
      gradingHistory
      createdAt
      updatedAt
    }
  }
`;
